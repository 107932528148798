import { Component, ErrorHandler, Injector, NgZone, OnInit, inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { EnvironmentService } from '@mt-ng2/environment-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthConfig, AuthService } from '@mt-ng2/auth-module';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { LoginConfig } from '@mt-ng2/login-module';

interface IResetPasswordForm {
    Password: FormControl<string | null>;
    ConfirmPassword: FormControl<string | null>;
}

@Component({
    selector: 'mt-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.less'],
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm: FormGroup<IResetPasswordForm>;
    logoFull: string;
    userId?: number;
    resetKey?: string;
    passwordType = 'password';
    confirmPasswordType = 'password';

    protected authService: AuthService;
    protected authConfig: AuthConfig;
    protected router: Router;
    public route: ActivatedRoute;
    protected notificationsService: NotificationsService;
    protected environmentService: EnvironmentService;
    public config: LoginConfig;
    protected globalErrorHandler: ErrorHandler;
    private _ngZone = inject(NgZone);

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.authConfig = injector.get(AuthConfig);
        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
        this.notificationsService = injector.get(NotificationsService);
        this.environmentService = injector.get(EnvironmentService);
        this.config = injector.get(LoginConfig);
        this.globalErrorHandler = injector.get(ErrorHandler);
        this.logoFull = this.config.useAssetsFolderForImages
            ? `${this.environmentService.config.assetsPath}logo-full.png`
            : `${this.environmentService.config.imgPath}logo-full.png`;

        this.resetPasswordForm = new FormGroup({
            Password: new FormControl('', [(c) => Validators.required(c), Validators.pattern(this.config.passwordPattern)]),
            ConfirmPassword: new FormControl('', [(c) => Validators.required(c), Validators.pattern(this.config.passwordPattern)]),
        });
    }

    ngOnInit(): void {
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the login page.  Because you aren't authed!
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        const queryParams = this.route.snapshot.queryParams;
        this.resetKey = queryParams['resetKey'];
        this.userId = +queryParams['userId'];
        if (!this.resetKey || !this.userId) {
            this._ngZone.run(() => {
                void this.router.navigate([this.authConfig.paths.homePath]);
            });
        }
    }

    togglePasswordType(): void {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }

    toggleConfirmPasswordType(): void {
        this.confirmPasswordType = this.confirmPasswordType === 'password' ? 'text' : 'password';
    }

    passwordHasError(): boolean {
        const control = this.resetPasswordForm.get('Password');
        return control?.errors && (control.touched || control.errors['maxlength']);
    }

    showPasswordRequiredError(): boolean {
        const control = this.resetPasswordForm.get('Password');
        return (control?.touched && control.hasError('required')) ?? false;
    }

    showConfirmPasswordRequiredError(): boolean {
        const control = this.resetPasswordForm.get('ConfirmPassword');
        return (control?.touched && control.hasError('required')) ?? false;
    }

    showPasswordMustMatchError(): boolean {
        const control = this.resetPasswordForm.get('Password');
        const controlValue = control?.value;
        const confirmControl = this.resetPasswordForm.get('ConfirmPassword');
        const confirmControlValue = confirmControl?.value;
        return (
            (control?.touched &&
                confirmControl?.touched &&
                (controlValue?.length ?? 0) > 0 &&
                (confirmControlValue?.length ?? 0) > 0 &&
                controlValue !== confirmControlValue) ??
            false
        );
    }

    hasRegexError(): boolean {
        let answer = false;
        const passwordControl = this.resetPasswordForm.controls.Password;
        if (passwordControl.errors?.['pattern']) {
            answer = true;
            passwordControl.setErrors({ pattern: true });
        }
        return answer;
    }

    onSubmit(): void {
        const form = this.resetPasswordForm;
        if (this.authService.matchPassword(form, undefined)) {
            if (form.valid) {
                this.authService.resetPassword(this.userId, form.value.Password, form.value.ConfirmPassword, this.resetKey).subscribe({
                    next: () => {
                        this.success();
                    },
                    error: (error) => {
                        if (error.status === 418) {
                            this.notificationsService.error(LoginConfig.signInFailure);
                        } else if (error.status === 400) {
                            if (error.error) {
                                if (typeof error.error === 'string') {
                                    this.error(error.error as string);
                                } else if (typeof error.error?.ModelState?.Service === 'string') {
                                    this.error(error.error.ModelState.Service as string);
                                } else {
                                    this.globalErrorHandler.handleError(error);
                                }
                            } else {
                                this.error('Something went wrong, Please generate a new reset email');
                                console.error(`Oops something went wrong!
                                - Probably the key has been used or expired.
                                - Or you did something you weren't supposed to do.
                                - Your best bet is to just generate a new email.`);
                            }
                        }
                    },
                });
            } else {
                markAllFormFieldsAsTouched(form);
            }
        } else {
            this.error('Passwords do not match');
        }
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Save Failed');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Password Updated Successfully');
        this.resetPasswordForm.reset();
    }
}
